








import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { syncOrg } from '@/api/system/OrganizationApi';

@Component({
  name: 'DeptList',
  components: {}
})
export default class DeptSycn extends Vue {
  /**
   * onMounted
   */
  mounted() {}

  // 同步部门
  handleSync() {
    // 请求参数
    let params = {};
    // 调用接口
    syncOrg(params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    });
  }
}
