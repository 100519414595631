var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.handleSync } },
          [_vm._v(_vm._s(_vm.$t("lang_sync_data")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }